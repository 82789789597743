<template>
    <v-data-table
        class="q-carrier-products-table"
        :items="filteredProducts"
        :headers="tableHeaders"
        :mobile-breakpoint="0"
        :expanded.sync="expanded"
        :single-expand="true"
        item-key="Product"
        :loading="loading"
        @click:row="onRowClick"
    >
        <template #body.prepend v-if="showFilters">
            <!-- TODO: Move to another component -->
            <tr v-if="!isMobile">
                <td colspan="2">
                    <v-select :items="filterProductGroups" v-model="filters.ProductSolution" />
                </td>
                <template>
                    <td v-for="f in ['eAppInd', 'PaperAppInd', 'InstantDecisionInd', 'FlexiblePremiumPossibleInd']" :key="f" class="text-center">
                        <q-tri-state-toggle v-model="filters[f]"></q-tri-state-toggle>
                    </td>
                </template>
            </tr>
            <td v-else colspan="999" class="px-0 pb-8">
                <table style="width: 100%;">
                    <tr>
                        <td class="text-start">Product group</td>
                        <td>
                            <v-select :items="filterProductGroups" v-model="filters.ProductSolution" />
                        </td>
                    </tr>
                    <tr v-for="f in ['eAppInd', 'PaperAppInd', 'InstantDecisionInd', 'FlexiblePremiumPossibleInd']"
                        :key="f" class="text-center"
                    >
                        <td class="text-start">
                            {{ actionNamesMap[f] }}
                        </td>
                        <td class="text-start">
                            <q-tri-state-toggle v-model="filters[f]"></q-tri-state-toggle>
                        </td>
                    </tr>
                </table>
            </td>
        </template>

        <template #expanded-item="{ item }">
            <!-- TODO: Move to another component -->
            <td v-if="isMobile" class="pa-0" colspan="999">
                <v-simple-table style="background: rgba(0, 0, 0, 0.1);">
                    <tbody>
                        <tr @click="clicked(item.carrier.CarrierEAppURL, true)">
                            <td class="pl-8">eApp</td>
                            <td class="text-right">
                                <v-icon :color="item.eAppInd == '1' ? 'success' : 'red'">
                                    fas {{ item.eAppInd == '1' ? 'fa-external-link-alt' : 'fa-times' }}
                                </v-icon>
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-8">Paper App</td>
                            <td class="text-right">
                                <v-icon :color="item.PaperAppInd == '1' ? 'success' : 'red'" >
                                    fas {{ item.PaperAppInd == '1' ? 'fa-check' : 'fa-times' }}
                                </v-icon>
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-8">Instant</td>
                            <td class="text-right">
                                <v-icon :color="item.InstantDecisionInd == '1' ? 'success' : 'red'" >
                                    fas {{ item.InstantDecisionInd == '1' ? 'fa-check' : 'fa-times' }}
                                </v-icon>
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-8">Flex Payment</td>
                            <td class="text-right">
                                <v-icon :color="item.ProductGuide == '1' ? 'success' : 'red'" >
                                    fas {{ item.ProductGuide == '1' ? 'fa-check' : 'fa-times' }}
                                </v-icon>
                            </td>
                        </tr>
                        <tr v-if="item.ProductGuide" @click="clicked(item.ProductGuide, true)">
                            <td class="pl-8">Product guide</td>
                            <td class="text-right">
                                <v-icon>fas fa-file</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </td>
        </template>

        <template #item.eAppInd="{ item }">
            <div class="text-center">
                <v-icon
                    :color="item.eAppInd == '1' ? 'success' : 'red'"
                    @click="clicked(item.carrier.CarrierEAppURL, true)"
                >
                    fas {{ item.eAppInd == '1' ? 'fa-external-link-alt' : 'fa-times' }}
                </v-icon>
            </div>

        </template>

        <template v-for="name in ['PaperAppInd', 'InstantDecisionInd', 'FlexiblePremiumPossibleInd']" #[`item.${name}`]="{ item }">
            <div class="text-center" :key="name">
                <v-icon :color="item[name] == '1' ? 'success' : 'red'" >
                    fas {{ item[name] == '1' ? 'fa-check' : 'fa-times' }}
                </v-icon>
            </div>
        </template>

        <template #item.ProductGuide="{ item }">
            <div class="text-center">
                <a v-if="item.ProductGuide" @click="clicked(item.ProductGuide, true)">
                    <v-icon>fas fa-file</v-icon>
                </a>
            </div>
        </template>

        <template #item.q-expand-icon>
            <v-icon>fas fa-chevron-down</v-icon>
        </template>
    </v-data-table>
</template>

<script>
import QTriStateToggle from '../utils/QTriStateToggle.vue'

export default {
    props: {
        products: { type: Array },
        showFilters: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {
            expanded: [],
            filters: {
                ProductSolution: 'All',
                eAppInd: '',
                PaperAppInd: '',
                InstantDecisionInd: '',
                FlexiblePremiumPossibleInd: '',
            },
            actionNamesMap: {
                'eAppInd' :'eApp',
                'PaperAppInd': 'Paper App',
                'InstantDecisionInd': 'Instant',
                'FlexiblePremiumPossibleInd': 'Flex Payment',
            }
        };
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },

        tableHeaders() {
            const icons = [
                {
                    text: 'eApp',
                    value: 'eAppInd',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Paper App',
                    value: 'PaperAppInd',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Instant',
                    value: 'InstantDecisionInd',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Flex Payment',
                    value: 'FlexiblePremiumPossibleInd',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'ProductGuide',
                    align: 'center',
                    sortable: false,
                }
            ];

            const defaultHeaders = [
                {
                    text: 'Product Group',
                    value: 'ProductSolution',
                },
                {
                    text: 'Product',
                    value: 'Product',
                },
            ]

            const expandIcon = {
                text: '',
                value: 'q-expand-icon',
                align: 'end',
            }

            if (this.isMobile) {
                return [
                    ...defaultHeaders,
                    expandIcon
                ]
            }

            return [...defaultHeaders, ...icons];
        },

        filterProductGroups() {
            var groups = this.products
                .map(product => product.ProductSolution)
                .filter(item => item !== null)

            return  ['All', ...new Set(groups)]
        },

        filteredProducts() {
            return this.products.filter(product => {
                //ProductSolution (Product Group)
                if (this.filters.ProductSolution != 'All' && product.ProductSolution != this.filters.ProductSolution)
                    return false

                //eAppInd
                if (this.filters.eAppInd == '1' && product.eAppInd != '1')
                    return false
                if (this.filters.eAppInd == '0' && product.eAppInd != '0')
                    return false

                //PaperAppInd
                if (this.filters.PaperAppInd == '1' && product.PaperAppInd != '1')
                    return false
                if (this.filters.PaperAppInd == '0' && product.PaperAppInd != '0')
                    return false

                //InstantDecisionInd
                if (this.filters.InstantDecisionInd == '1' && product.InstantDecisionInd != '1')
                    return false
                if (this.filters.InstantDecisionInd == '0' && product.InstantDecisionInd != '0')
                    return false

                //FlexiblePremiumPossibleInd
                if (this.filters.FlexiblePremiumPossibleInd == '1' && product.FlexiblePremiumPossibleInd != '1')
                    return false
                if (this.filters.FlexiblePremiumPossibleInd == '0' && product.FlexiblePremiumPossibleInd != '0')
                    return false

                return true
            })
        }
    },

    methods: {
        onRowClick(event, item) {
            item.expand(!item.isExpanded);
        }
    },
    components: {
        QTriStateToggle
    }
};
</script>

<style lang="scss">
    .q-carrier-products-table {
        .v-data-table__expanded__content {
            box-shadow: none !important;
        }
        .v-data-table__expanded {
            background: rgba(0, 0, 0, 0.1);
        }
    }
</style>